
.smooth-image-wrapper {
  .smooth-image {
    transition: opacity 1s;

  }

  .img-visible {
    display: block;
    opacity: 1;
  }

  .img-hidden {
    display: none;
    opacity: 0;
  }

  @keyframes preloader-block {
    from {
      background-position: 0%, 0;
    }
    to {
      background-position: 170%, 0;
    }
  }
}

html {
  font-size: 10px;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.month {
  width: 100%;
  margin-top: 24px !important;
}
.calendar .months-container .month-container {
  height: initial !important;
}
.calendar {
  width: 300px;
}

.month-title {
  padding-bottom: 35px !important;
  text-align: left !important;
}

.day-content {
  padding: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  border-radius: 0 !important;
}
.day {
  width: 35px !important;
  height: 35px !important;
  border: 1px solid gray !important;
}

.DayPicker-Day {
  padding: 1.5em !important;
  border-radius: 0 !important;
  background-color: #f9e0e7 !important;
  border: 1.71816px solid #c5c5c5;
  color: #000 !important;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
  background-color: transparent !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #e36285 !important;
  color: #fff !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #e36285 !important;
  color: #fff !important;
}

.DayPicker-Weekday {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #000 !important;
}

.DayPicker-Caption {
  margin-bottom: 1em !important;
}

.DayPicker-Caption > div {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.DayPicker-Day--disabled {
  background: #FCFCFC !important;
  cursor: default;
}

   /* Example Styles for React Tags*/
.app {
  padding: 40px;
  text-align: center;
}

/* Example Styles for React Tags*/

.container {
  margin: auto;
  width: 50%;
}
.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField {
  border: 1px solid rgb(0 0 0 / 23%);
  width: 478px;
  padding: 18.375px 10px;
  margin: 0;
  font-size: 2rem;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  min-width: 150px;
  border-radius: 5px;
}

.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
  color:rgba(0, 0, 0, 0.35);
}

.ReactTags__tagInput:focus input.ReactTags__tagInputField:focus {
  border-color: rgb(33, 142, 214);

}

input.ReactTags__tagInputField:focus-visible {
  border-color: rgb(33, 142, 214);
  border: 1px solid rgb(33, 142, 214);
}

.ReactTags__tagInput:hover input.ReactTags__tagInputField:hover {
  border-color: rgb(0, 0, 0);
  /* border: 1px solid rgb(33, 142, 214); */
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: rgb(249, 224, 231);
  color: #000;
  font-size: 2rem;
  display: inline-block;
  padding: 5px;
  margin: 10px 5px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
}
.ReactTags__selected a.ReactTags__remove {
  color: #000;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: black;
  font-size: 3rem;
}

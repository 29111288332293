.calendar {
  width: 350px;
  margin-top: 24px;
}

.calendarWeeks {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calendarRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #C5C5C5;
}

.cellCalendar{
  width: 50px;
  font-size: 14px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}

.header{
  margin-bottom: 18px;
}

.datetime{
  font-size: 18px;
  font-weight: 700;
}

.cell-calendar{
  width: 50px;
  height: 50px;
  display: flex;
  font-size: 12px;
  background: transparent;
  justify-content: center;
  align-items: center;
  border: 1px solid #C5C5C5;
  cursor: pointer;
}

.cell-calendar:hover{
  background-color: #F9E0E7;
}